.product {

    &__image {
        padding-bottom: 133.33%;
        display: block;
        border: 1px solid $frst-thm-clr;
    }

    &__cat {
        color: $thrd-fnt-clr;
        margin-bottom: 7px;
    }

    &__title {
        margin-top: 20px;
        margin-bottom: 7px;
    }
}

@include breakpoint(medium down) {

    .product {

        &__col {
            margin-bottom: 20px;
        }
    }
}