// Utils
@import 'inc/mixins';
@import 'inc/foundation-custom';
// Plugins
@import 'plugins/fontawesome.min.scss';
@import 'plugins/slick.scss';
//@import 'plugins/jquery.fancybox.v2.scss'; // Uncomment this line and comment line above to use Fancybox v.2
@import 'plugins/jquery.fancybox.v3.scss';
// Home slider
@import 'inc/slider';
@import 'flexible/flexible';
/* -----------------------------------------
   WordPress Core Classes
----------------------------------------- */
@import 'inc/wp-core';

/* -----------------------------------------
   General fixes
----------------------------------------- */

html {
  height: 100%;
}

body {
  position: static !important;
  display: flex;
  flex-direction: column;
  height: auto;
  min-height: 100vh;
}

main {
  display: block;
}

.grid-container {
  width: 100%;
}

*:last-child {
  margin-bottom: 0;
}

.layout {
  padding-top: 50px;
  padding-bottom: 50px;

  @include breakpoint(medium down) {
    padding-top: 25px;
    padding-bottom: 25px;
  }
}

.image-link {
  position: relative;

  &::before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: $eighth-thm-clr;
    opacity: 0;
    transition: opacity .3s ease-in-out;
  }

  &:focus,
  &:hover {

    &::before {
      opacity: 1;
    }
  }
}

.d-inline-block {
  display: inline-block;
}

.ov-x-hidden {
  overflow-x: hidden;
}

/* ---------------------------------------
    Typography / Forms / Inputs / Buttons
--------------------------------------- */

@import 'typography';

/* -----------------------------------------
    Contact Form
----------------------------------------- */

/*basic Gravity Form styles and fixes, if you dont need/use this -
comment or delete styles below*/

@import 'inc/gform';

/* ------------------------------------
    Basic Styles
------------------------------------- */

/* Preloader */

@keyframes spin {
  100% {
    transform: rotate(360deg);
  }
}

.preloader {
  position: fixed;
  z-index: 100000000;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  visibility: visible;
  opacity: 1;
  background-color: #fff;
  color: #222;
  transition: opacity 0.4s ease, visibility 0.4s ease;

  &__icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    &::before {
      content: '\f1ce';
      display: block;
      font-family: $fa;
      font-size: 40px;
      font-weight: 900;

      animation: spin 1s ease-in-out infinite;
    }
  }

  &--hidden {
    visibility: hidden;
    opacity: 0;
  }
}

/* Position center */

.position-center {
  @include position-center();
}

/* Pagination */

ul.pagination {
  @include list-unstyled;
  margin: 15px 0;
  text-align: center;

  li {
    display: inline-block;
    margin: 0 3px;
    padding: 0;
    line-height: 1.5;

    span, a {
      display: block;
      padding: 0 7px;

      &.current {
        display: block;
        background: $main-color;
        color: #fff;
      }
    }
  }
}

/* Search form */

.search {
  position: relative;
  margin-top: 20px;

  .posts-list & {
    margin-bottom: 40px;
  }

  &__input {
    border: none;
    border-bottom: 1px solid $frst-fnt-clr;
    background-color: transparent;
    color: $frst-fnt-clr;

    &::placeholder {
      color: $frst-fnt-clr;
    }
  }
}

.widget {

  .search {
    display: none;
  }
}

.search__submit {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  height: auto;
  padding: 10px 15px;
  cursor: pointer;
  display: none;
  //font-size: 0;

  &::after {
    //content: "\f002";
    font-family: $fa;
    font-size: 20px;
    font-weight: 900;
  }
}

//use @extend %bgcover; to selector if you need background cover;

%bgcover {
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: cover;
}

.bg-cover {
  @extend %bgcover;
}

//use @extend %bgcontain; to selector if you need contain image in block;

%bgcontain {
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: contain;
}

.bg-contain {
  @extend %bgcontain;
}

.two-columns {
  @include breakpoint(medium) {
    columns: 2;
  }
}

.three-columns {
  @include breakpoint(medium) {
    columns: 3;
  }
}

/* ------------------------------------
    Content Styles
------------------------------------- */

/* Comments block */

#comments {
  padding-top: 30px;
}

.comment-list {
  @include list-unstyled;
  margin-bottom: 20px;
}

.comment {
  position: relative;
  margin-top: 15px;

  .children {
    list-style: none;
  }
}

.comment-meta {
  font-size: 14px;
}

.comment-metadata {
  margin-top: 5px;
  color: #666;
}

.comment-content {
  margin-top: 15px;
  padding-left: 15px;
  border-left: 1px solid #ddd;
  font-size: 14px;
}

.reply {
  position: absolute;
  top: 0;
  right: 0;
  text-align: right;
}

.comment-reply-link {
  display: block;
  padding: 0.33333rem 0.5rem;
  border-radius: 0;
  background: #777;
  color: #fefefe;
  font-size: 0.8rem;
  line-height: 1;
  white-space: nowrap;

  &:hover, &:focus {
    background-color: $main-color;
    color: #fff;
    text-decoration: none;
  }
}

/* 404 page */

.not-found {
  padding-top: 100px;
  padding-bottom: 100px;

  .label {
    background-color: $main-color;
    text-decoration: none;
    cursor: pointer;

    &:hover {
      background-color: darken($main-color, 8);
      color: #fff;
    }
  }
}

.list-unstyled {
  @include list-unstyled;
}

.d {
  &-block {
    display: block;
  }
  &-flex {
    display: flex;
  }
}

.gradient {
  /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#00aa00+0,006600+100 */
  background: #00aa00; /* Old browsers */
  background: -moz-linear-gradient(45deg, #00aa00 0%, #006600 100%); /* FF3.6-15 */
  background: -webkit-linear-gradient(45deg, #00aa00 0%, #006600 100%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(45deg, #00aa00 0%, #006600 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00aa00', endColorstr='#006600', GradientType=1); /* IE6-9 fallback on horizontal gradient */
  padding-top: 75px;
  padding-bottom: 75px;

  &--small {
    padding-top: 60px;
    padding-bottom: 60px;
  }
}

/* ---------------------------------------
    Repeatable blocks
--------------------------------------- */

@import 'repeatable-blocks';

/* ---------------------------------------
    Header
--------------------------------------- */

@import 'header';

/* ---------------------------------------
    Sidebar
--------------------------------------- */

@import 'sidebar';

/* ---------------------------------------
    Footer
--------------------------------------- */

@import 'footer';

/*---------------------------------------
    TMPL: Home page
---------------------------------------*/

@import 'templates/home';

/* ---------------------------------------
    Page: Blog
--------------------------------------- */

@import 'templates/blog';

/* ---------------------------------------
    TMPL: Default page
--------------------------------------- */

@import 'templates/default';

/* ---------------------------------------
    TMPL: Contact page
--------------------------------------- */

@import 'templates/contact';

/* ---------------------------------------
    TMPL: Demo page
--------------------------------------- */

@import 'templates/demo';

/* ---------------------------------------
    Woocommerce
--------------------------------------- */

@import 'woocommerce/woocommerce';

/* ---------------------------------------
    Archive
--------------------------------------- */

@import 'templates/archive';

/* ---------------------------------------
    TMPL: Brands Page
--------------------------------------- */

@import 'templates/brands';
