.subscription {

  &__title {
    margin-bottom: 55px;
    padding-left: 15px;
    padding-right: 15px;
  }
}

.subscr-form {

  &_wrapper {
    max-width: 760px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 15px;
    padding-right: 15px;
  }

  .gform {

    &_button {

      &::before {
        background-color: $sxth-thm-clr;
        border: 1px solid $frst-thm-clr;
      }

      &::after {
        background-color: $ffth-thm-clr;
      }

      &:hover,
      &:focus {

        &::after {
          background-color: $sxth-thm-clr;
        }
      }
    }

    &_fields {
      @include list-unstyled;
      display: flex;
      flex-wrap: wrap;
      margin-left: -15px;
      margin-right: -15px;
    }
  }

  .gfield {
    margin-left: 15px;
    margin-right: 15px;
    flex: 0 0 calc(50% - 30px);
    max-width: calc(50% - 30px);
  }
}

@include breakpoint(medium) {

  .subscription {

    .gform {

      &_button {
        margin-top: -125px;
      }
    }
  }
}

@include breakpoint(small down) {

  .subscr-form {

    .gform {

      &_footer {
        text-align: center;
      }
    }

    .gfield {
      margin-left: 15px;
      margin-right: 15px;
      flex: 0 0 calc(100% - 30px);
      max-width: calc(100% - 30px);
    }
  }
}
