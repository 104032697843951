.brands {

  &__list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    @include breakpoint(large down) {
      margin-left: -10px;
      margin-right: -10px;
    }
  }

  &__item {
    flex: 0 0 auto;
    width: calc(50% - 20px);
    text-align: center;
    border: 1px solid $frst-thm-clr;
    margin: 10px;
    height: 100px;

    @include breakpoint(small down) {
      width: calc(100% - 20px);
      min-height: 100px;
    }
  }

  &__link {
    height: 100%;
    display: block;
    text-transform: uppercase;
    font-weight: 700;
    vertical-align: middle;
    padding: 10px;

    &:not(&--all) {
      font-size: 0;
      white-space: nowrap;

      &::after {
        content: '';
        display: inline-block;
        width: 0;
        height: 100%;
        vertical-align: middle;
      }
    }

    img {
      max-height: 100%;
      vertical-align: middle;
    }

    &--all {
      @include vert-align;
      font-size: inherit;
    }
  }
}

.products-list {

  &__sidebar {
    @include breakpoint(large down) {
      margin-bottom: 30px;
    }
  }

  &__more {
    opacity: 1;
    visibility: visible;
    transition: opacity .3s ease-in-out, visibility .3s ease-in-out;

    .btn-prel {
      opacity: 0;
      visibility: hidden;
    }

    &--hide {
      visibility: hidden;
      opacity: 0;
    }

    &--inactive {
      pointer-events: none;

      .btn-prel {
        opacity: 1;
        visibility: visible;
      }
    }
  }
}

.products {
  position: relative;
}

.btn-prel,
.prod-preloader {
  position: absolute;
  z-index: 10;
  opacity: 1;
  visibility: visible;
  transition: opacity .3s ease-in-out, visibility .3s ease-in-out;

  &__icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    &::before {
      content: '\f1ce';
      display: block !important;
      font-family: $fa;
      font-size: 40px;
      font-weight: 900;

      animation: spin 1s ease-in-out infinite;
    }
  }
}

.prod-preloader {
  top: -15px;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: $eighth-thm-clr;

  &--hidden {
    opacity: 0;
    visibility: hidden;
  }
}


.btn-prel {
  background-color: $twlwth-thm-clr;
  top: -5px;
  left: -5px;
  right: -5px;
  bottom: -5px;

  &__icon {

    &::before {
      font-size: 20px;
    }
  }
}
