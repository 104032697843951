checkbox.gform_wrapper {
  width: 100%;

  ul {
    list-style: none;
  }
}

.gform_fields {
  margin: 0;

  &::after {
    content: '';
    display: table;
    clear: both;
  }

  .select-styled,
  textarea,
  input {
    border: none;
    border-bottom: 1px solid $frst-thm-clr;
    background-color: transparent;
    color: $frst-thm-clr;
  }

  .select {
    position: relative;
    z-index: 5;
    cursor: pointer;
  }
}


.select-options {
  border-top: none;
  position: absolute;
  z-index: 999;
  top: 100%;
  right: 0;
  left: 0;
  display: none;
  margin: 0;
  padding: 0;
  list-style: none;
  max-height: 300px;
  overflow: auto;
  border: 1px solid $frst-thm-clr;
  background-color: $frth-thm-clr;

  li {
    padding: 5px 10px;
    cursor: pointer;
    transition: color .3s ease-in-out, background-color .3s ease-in-out;

    &:hover {
      color: $hvr-clr;
      background-color: $eighth-thm-clr;
    }
  }
}

.gform {

  &_button {
    background-color: transparent;
    position: relative;
    z-index: 1;
    margin-right: 5px;
    margin-top: 5px;
    margin-bottom: 5px;
    min-width: 152px;
    color: $frst-fnt-clr !important;

    &::before {
      content: '';
      display: block;
      position: absolute;
      background-color: $scnd-thm-clr;
      top: 5px;
      left: 5px;
      bottom: -5px;
      right: -5px;
      z-index: -1;
      transition: top .2s ease-in-out, left .2s ease-in-out;
    }

    &::after {
      content: '';
      display: block;
      position: absolute;
      background-color: $frth-thm-clr;
      border: 1px solid $frst-thm-clr;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: -1;
      transition: background-color .2s ease-in-out .2s;
    }

    &:hover,
    &:focus {

      &::after {
        background-color: $scnd-thm-clr;
      }

      &::before {
        top: -5px;
        left: -5px;
      }
    }
  }
}

.gfield {
  position: relative;
  margin-bottom: 30px;
  list-style: none;

  &_checkbox {

    li + li {
      margin-top: 25px;
    }

    input[type="checkbox"] {
      width: 0;
      height: 0;
      position: absolute;
      visibility: hidden;

      + label {
        position: relative;
        margin: 0;
        padding-left: 30px;
        font-size: 14px;
        line-height: 1.7;
        color: $frst-fnt-clr;

        &::before {
          content: '';
          border: 1px solid $frst-fnt-clr;
          width: 12px;
          height: 12px;
          display: block;
          position: absolute;
          left: 0;
          top: 5px;
          bottom: 0;
        }

        &::after {
          content: '\f00c';
          font-family: 'Font Awesome 5 Free';
          font-weight: 900;
          position: absolute;
          left: 0;
          top: 0;
          bottom: 0;
          display: none;
        }
      }

      &:checked {

        + label {

          &::after {
            display: block;
          }
        }
      }
    }
  }

  &_required {
    display: none;
  }

  &_select {
    background-image: none;
  }

  &_label {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    padding: 0 15px;
    transition: top .25s ease-out, transform .25s ease-out, font-size .25s ease-out, color .25s ease-out, padding-left .25s ease-out;
    padding-top: 8px;

    &:focus {
      top: 0;
    }
  }

  &__focus {

    .ginput_container {

      &::after {
        opacity: 1;
      }
    }

    .gfield_label {
      transform: translateY(-100%);
      font-size: 12px;
      padding-left: 0;
    }
  }
}

.gform_hidden {
  display: none;
}

@include breakpoint(medium) {
  .gf_left_half,
  .gf_right_half {
    float: left;
    width: 50%;

    + .gfield:not(.gf_left_half):not(.gf_right_half) {
      clear: both;
    }
  }

  .gf_left_third,
  .gf_middle_third,
  .gf_right_third {
    float: left;
    width: 33.3333%;

    + .gfield:not(.gf_left_third):not(.gf_middle_third):not(.gf_right_third) {
      clear: both;
    }
  }

  .gf_left_half {
    padding-right: #{-zf-get-bp-val($grid-margin-gutters, 'large') / 2};
  }

  .gf_right_half {
    padding-left: #{-zf-get-bp-val($grid-margin-gutters, 'large') / 2};

    + .gfield {
      clear: both;
    }
  }

  .gf_left_third {
    padding-right: #{-zf-get-bp-val($grid-margin-gutters, 'large') * 2 / 3};
  }

  .gf_right_third {
    padding-left: #{-zf-get-bp-val($grid-margin-gutters, 'large') * 2 / 3};

    + .gfield {
      clear: both;
    }
  }

  .gf_middle_third {
    padding-left: #{-zf-get-bp-val($grid-margin-gutters, 'large') / 3};
    padding-right: #{-zf-get-bp-val($grid-margin-gutters, 'large') / 3};
  }
}

.ginput_complex {
  span {
    display: block;
  }

  @include breakpoint(medium) {
    margin-left: -#{-zf-get-bp-val($grid-margin-gutters, 'large') / 2};
    margin-right: -#{-zf-get-bp-val($grid-margin-gutters, 'large') / 2};

    &::after {
      content: '';
      display: table;
      clear: both;
    }

    span {
      display: block;
      float: left;
      width: 50%;
      padding-left: #{-zf-get-bp-val($grid-margin-gutters, 'large') / 2};
      padding-right: #{-zf-get-bp-val($grid-margin-gutters, 'large') / 2};
    }
  }

  label {
    display: none;
  }
}

.hidden_label {
  .gfield_label {
    clip: rect(1px, 1px, 1px, 1px);
    position: absolute !important;
    height: 1px;
    width: 1px;
    overflow: hidden;
  }
}

.ginput_container ul {
  margin-left: 0;
  list-style-type: none;
}

.gfield_contains_required .ginput_container_phone {
  .validation_message {
    display: none;
  }
}

.gfield_error {
  input, textarea, select {
    border-color: #ec7a7a;
  }
}

.gfield_description {
  margin-top: 10px;
}

.gform_confirmation_message {
  margin-bottom: 15px;
}

.gform_footer {
  position: relative;
  text-align: right; /*aligning of submit button*/

  @include breakpoint(small down) {
    text-align: center;
  }
}

.gform_ajax_spinner {
  position: absolute;
  top: 0;
  right: 10px;
  bottom: 0;
  margin: auto;
}

.validation_error {
  width: 100%;
  margin-bottom: 15px;
  padding: 5px;
  border: 1px solid #ec7a7a;
  background-color: #f7e4e1;
  color: $text-color;
  font-size: 14px;
  text-align: left;
}

.validation_message {
  position: absolute;
  z-index: 3;
  top: calc(100% + 7px);
  left: 15px;
  display: inline-block;
  margin: 0;
  padding: 3px 6px;
  background-color: #f7e4e1;
  color: #333;
  font-size: 12px;
  font-style: normal;
  letter-spacing: normal;
  text-transform: none;
  border-radius: 2px;
  box-shadow: 1px 1px 3px rgba(#000, 0.3);

  &::before {
    content: '!';
    display: inline-block;
    vertical-align: middle;
    margin-right: 5px;
    width: 14px;
    height: 14px;
    background-color: #efae4d;
    color: #fff;
    font-weight: 700;
    text-align: center;
    line-height: 14px;
    border-radius: 2px;
  }

  &::after {
    content: '';
    position: absolute;
    left: 20px;
    bottom: 100%;
    width: 14px;
    height: 7px;
    border-style: solid;
    border-width: 0 7px 7px 7px;
    border-color: transparent transparent #f7e4e1 transparent;
  }
}

.ginput_container_fileupload {
  .validation_message:empty {
    display: none;
  }
}

// Datepicker styles

input.datepicker_with_icon {
  width: calc(100% - 24px);
  display: inline-block;
  vertical-align: middle;
  margin-right: 5px;
}

.ui-datepicker {
  position: absolute;
  z-index: 9999 !important;
  width: 216px;
  height: auto;
  margin: 5px auto 0;
  font-size: 11px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.5);

  a {
    text-decoration: none;
  }

  table {
    width: 100%;
    border-collapse: collapse;
  }

  thead {
    border-bottom: 1px solid #bbb;
    background: #f7f7f7;
    color: $dark-gray;

    th {
      padding: 3px;
      border: 1px solid #ddd;
      text-align: center;
    }
  }

  tbody {
    border: none;

    td {
      width: 31px;
      padding: 0;
      border: 1px solid #bbb;

      span, a {
        display: block;
        //width: 30px;
        height: 29px;
        color: #666;
        font-weight: 700;
        line-height: 30px;
        text-align: center;
      }
    }

    th {
      padding: 5px 0;
      color: #666;
      font-size: 6pt;
      text-transform: uppercase;
      text-align: center;
    }
  }

  select.ui-datepicker-month,
  select.ui-datepicker-year {
    height: 30px;
    margin: 0 2.5% 0;
    padding: 5px 20px 5px 5px;
    font-size: 13px;
    line-height: 20px;
  }
}

.ui-datepicker-header {
  position: relative;
  padding: 5px 0;
  border: none;
  background-color: #666; /* set the header background color */
  color: #fff;
  font-weight: 700;

  &::after {
    content: '';
    display: table;
    clear: both;
  }
}

.ui-datepicker-prev,
.ui-datepicker-next {
  position: absolute;
  top: 50%;
  padding: 10px;
  color: #fff;
  font-size: 0;
  line-height: 1.4;
  transform: translateY(-50%);

  &:hover, &:focus {
    color: #fff;
  }

  &::before {
    content: '';
    font-family: $fa;
    font-weight: 900;
    font-size: 12px;
  }
}

.ui-datepicker-next {
  right: 0;

  &::before {
    content: '\f054';
  }
}

.ui-datepicker-prev {
  left: 0;

  &::before {
    content: '\f053';
  }
}

.ui-datepicker-title {
  display: flex;
  padding-right: 35px;
  padding-left: 35px;
  justify-content: center;
}

.ui-datepicker-calendar {
  margin: 0 0 0 0 !important;

  .ui-state-default {
    background: #ededed;
  }

  .ui-state-hover {
    background: #fff;
  }

  .ui-state-active {
    position: relative;
    width: 32px;
    height: 31px;
    margin: -1px;
    border: 1px solid #c19163; /* set the active date border color */
    background: #fff2aa; /* set the active date background color */
  }
}

.ui-datepicker-unselectable {
  .ui-state-default {
    background: #f4f4f4;
    color: #b4b3b3;
  }

  &.ui-state-disabled {
    .ui-state-default {
      background-color: #d7d7d7;
      color: #b4b3b3;
    }

  }
}

.gform_validation_container,
.gform_wrapper .gform_validation_container,
body .gform_wrapper li.gform_validation_container,
body .gform_wrapper ul.gform_fields li.gfield.gform_validation_container {
  position: absolute !important;
  left: -9000px;
  display: none !important;
}

.gform_wrapper {
  .screen-reader-text,
  label.hidden_sub_label {
    border: 0;
    clip: rect(1px, 1px, 1px, 1px);
    clip-path: inset(50%);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
    word-wrap: normal !important;
  }
}
