@include breakpoint(large) {
  .checkout {
    .grid-margin-x > .large-4 {
      width: calc(37% - 1.875rem);
    }

    .grid-margin-x > .large-8 {
      width: calc(63% - 1.875rem);
    }
  }
}

.order_review_heading {

  &::before,
  &::after {
    display: none;
  }
}

.woocommerce-checkout {
  .page-title {
    display: none;
  }

  h1 {

    @include breakpoint(medium down) {
      margin-top: 20px;
    }
  }

  .main-content {
    @include breakpoint(large) {
      margin-top: 90px;
    }
  }

  .woocommerce-form-coupon-toggle {
    display: none;
  }
}

label[for='shipping_address_2'].screen-reader-text,
label[for='billing_address_2'].screen-reader-text {
  clip: auto;
  height: auto;
  overflow: visible;
  width: auto;
  word-wrap: normal !important;

  span {
    clip: rect(1px, 1px, 1px, 1px);
    height: 1px;
    overflow: hidden;
    position: absolute !important;
    width: 1px;
    word-wrap: normal !important;
  }
}

table.shop_table {

  &.woocommerce-checkout-review-order-table {
    border: none;

    .product {

      &-name {
        vertical-align: top;
      }

      &-name,
      &-total {
        border: none;
      }
    }

    .cart-subtotal,
    .order-total {
      font-size: 1.125rem;
      color: $frst-fnt-clr;

      th,
      td {
        border-top: none;
        text-align: right;
      }

      td {
        width: 170px;
        font-weight: 700;
      }

      th {
        width: auto;
        font-weight: 400;
      }
    }

    .order-total {
      font-size: 1.5rem; // 24px
    }
  }
}

.woocommerce {

  &-shipping-fields {
    padding-top: 30px;
  }

  &-additional-fields {
    margin-bottom: 30px;
  }

  &-input-wrapper {

    textarea,
    input {
      border-top: none;
      border-right: none;
      border-left: none;
      background-color: transparent;
    }
  }
}

.select2-container {
  &--default {
    .select2-selection {
      &--single {
        background-color: transparent;
        border-top: none;
        border-left: none;
        border-right: none;
        border-radius: 0;
        color: $frst-fnt-clr;
      }

      &--single .select2-selection__rendered {
        color: $frst-fnt-clr !important;
      }

      &--single .select2-selection__arrow b {
        border-top-color: $frst-fnt-clr !important;
      }
    }

    &__rendered {
      color: $frst-fnt-clr;
    }
  }
}

.checkbox {
  position: relative;
  cursor: pointer;

  .input-checkbox {
    width: 0;
    height: 0;
    position: absolute;
    visibility: hidden;

    + span {
      position: relative;
      margin: 0;
      padding-left: 30px;
      font-size: 14px;
      line-height: 1.7;
      color: $frst-fnt-clr;

      &::before {
        content: '';
        border: 1px solid $frst-fnt-clr;
        width: 12px;
        height: 12px;
        display: block;
        position: absolute;
        left: 0;
        top: 2px;
        bottom: 0;
      }

      &::after {
        content: '\f00c';
        font-family: 'Font Awesome 5 Free';
        font-weight: 900;
        position: absolute;
        left: 0;
        top: -3px;
        bottom: 0;
        display: none;
      }
    }

    &:checked {

      + span {

        &::after {
          display: block;
        }
      }
    }
  }
}

.input {
  &-text:not(.coupon__input) {
    color: $frst-fnt-clr;

    &::placeholder {
      opacity: 0;
    }
  }
}

.woocommerce-checkout {

  .col2-set {
    @include breakpoint(large) {
      max-width: 730px;
      margin-left: auto;

    }

    @include breakpoint(medium dowm) {
      margin-bottom: 30px;
    }
  }
}

.woocommerce-account,
.woocommerce-checkout {

  .col2-set {

    .form-row {
      position: relative;
      padding-top: 30px !important;

      label:not(.woocommerce-form__label-for-checkbox) {
        position: absolute;
        transition: transform 0.3s ease-in-out,
        font-size 0.3s ease-in-out,
        padding-left .3s ease-in-out;
        padding: 0 15px !important;
      }

      &--focus {
        label:not(.woocommerce-form__label-for-checkbox) {
          transform: translateY(-100%);
          font-size: 12px;
          padding-left: 0 !important;
        }
      }
    }
  }
}

label[for="shipping_country"],
label[for="billing_country"] {
  display: none !important;
}


.select2-results {
  &__option {
    color: $main-color;

    &--highlighted {
      background-color: $thrd-thm-clr !important;
    }
  }
}

.checkout-sidebar {
  .woocommerce-mini-cart__buttons.buttons {
    display: none;
  }

  .cart-menu {

    &__footer {
      border-top: 1px solid $frst-thm-clr;
      padding-top: 24px;
      margin-top: 24px;

      > * {
        display: flex;
        justify-content: flex-end;
        text-align: right;
      }
    }
  }

  .total-amount {
    width: 38%;
    max-width: 130px;
  }

  .price-total {
    > * {
      font-size: 1.5rem !important;

      @include breakpoint(small down) {
        font-size: 1.4rem !important;
      }
    }
  }

  .remove.remove_from_cart_button {
    position: absolute;
    top: 5px;
    right: 5px;
    z-index: 10;
    background-color: $frth-thm-clr;
    font-weight: 400 !important;
    width: 1rem !important;
    height: 1rem !important;
    font-size: 1rem !important;
    line-height: 1;
    color: $ffth-fnt-clr !important;
    text-align: center;
    border-radius: 50%;
    border: 1px solid currentColor;

    &:focus,
    &:hover {
      color: $frst-fnt-clr !important;
      background-color: $hvr-clr;
    }
  }

  .woocommerce-mini-cart-item {
    position: relative;
    display: flex;
    align-items: flex-start;
  }

  .woocommerce-mini-cart-item + .woocommerce-mini-cart-item {
    margin-top: 30px;
  }

  ul.cart_list li {
    a {
      display: inline-block;
    }

    img {
      float: none;
      width: 100px;
      margin-left: 0;
    }
  }
}
