.footer {
  margin-top: 50px;

  @include breakpoint(medium down) {
    margin-top: 25px;
  }

  &__end {
    padding-top: 26px;
    padding-bottom: 26px;
  }

  &__cell {
    position: relative;
    padding-top: 40px;

    &::before {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      border-top: 1px solid $svnth-thm-clr;

      @include breakpoint(large) {
        right: -15px;
      }
    }

    &:last-of-type {

      &::before {
        right: 0;
      }
    }

    @include breakpoint(medium down) {
      padding-top: 20px;
      padding-bottom: 20px;
    }
  }
}

.footer-menu {
  @include list-unstyled;

  li {
    display: inline-block;

    a {
      display: block;
    }
  }

  li + li {
    margin-left: 20px;

    @include breakpoint(710 down) {
      margin-left: 6px;
    }
  }
}


.soc-net {

  &--footer {
    margin-top: 30px;
  }

  &__item + &__item {

    &--footer {
      margin-left: 25px;
    }
  }

  &__link {
    font-size: 1.5625rem;

    &:hover,
    &:focus {
      color: $frst-fnt-clr;
    }

    &--footer {
      color: $scnd-fnt-clr;
    }
  }
}
