/* -----------------------------------------
   Home page slider
----------------------------------------- */

.slick-slider:not(.slick-initialized) {
  .slick-slide:first-child {
    display: block;
  }
}

#home-slider {
  margin-top: 10px;
  margin-bottom: 50px;

  &::after {
    content: '';
    display: table;
    clear: both;
  }

  .slick-slide {
    position: relative;
    width: 100%;
    height: 500px; /*define the height of slide*/
    transition: height 0.3s ease;
    outline: none;

    @extend %bgcover;

    @include breakpoint(medium down) {
      height: 500px;
    }

    @include breakpoint(small down) {
      height: 400px;
    }
  }

  .slider-caption {
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    transform: translateY(-50%);
    max-width: 480px;
    color: $frst-fnt-clr;
  }

  .videoHolder {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    overflow: hidden;
  }

  .video, .responsive-embed {
    position: absolute;
    pointer-events: none;

    @include position-center;
  }
}

.slick-arrow {
  /* Slick arrows global styles */
}

.slick-dots {
  /* Slick dots global styles */
  font-size: 0;

  li {
    &:only-child {
      display: none;
    }
  }
}
