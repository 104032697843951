/* ---------------------------------------
    TMPL: Default
--------------------------------------- */

.page-hero {
    height: 350px;
    background-repeat: no-repeat;
    background-position: center center;
}

.breadcrumbs {
    margin-top: 40px;
}

.breadcrumb {

  &_last {
    text-decoration: underline;
  }
}
