.sidebar {
  @include breakpoint(small down) {
    margin-top: 50px;
  }
}

.widget {
  margin-bottom: 20px;

  &:last-child {
    margin-bottom: 0;
  }
}
