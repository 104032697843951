// Project variables

$blue: #1a608b;

$text-color: #333;

$frst-thm-clr: #fff;
$scnd-thm-clr: #00EE00;
$thrd-thm-clr: #00AA00;
$frth-thm-clr: #000;
$ffth-thm-clr: #006600;
$sxth-thm-clr: #007A00;
$svnth-thm-clr: #CECECE;
$eighth-thm-clr: rgba(255, 255, 255, .3);
$twlwth-thm-clr: rgba(0, 0, 0, .7);

$frst-fnt-clr: #fff;
$scnd-fnt-clr: #00EE00;
$thrd-fnt-clr: #CCCCCC;
$ffth-fnt-clr: #00AA00;

$hvr-clr: $scnd-fnt-clr;

$main-color: #000;
$sub-color: $main-color;

$font: 'Open Sans', sans-serif;
$sub-font: $font;
$fa: 'Font Awesome 5 Free';
$fab: 'Font Awesome 5 Brands';

// Project mixins


// Custom fonts
