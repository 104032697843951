/* Slider */

.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0;

  &:focus {
    outline: none;
  }

  &.dragging {
    cursor: pointer;
    cursor: hand;
  }
}

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto;

  &::before,
  &::after {
    content: '';
    display: table;
  }

  &::after {
    clear: both;
  }

  .slick-loading & {
    visibility: hidden;
  }
}

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;

  [dir='rtl'] & {
    float: right;
  }

  img {
    display: block;
    max-width: 250px;
  }

  &.slick-loading img {
    display: none;
  }

  display: none;

  &.dragging img {
    pointer-events: none;
  }

  .slick-initialized & {
    display: block;
  }

  .slick-loading & {
    visibility: hidden;
  }

  .slick-vertical & {
    display: block;
    height: auto;
    border: 1px solid transparent;
  }
}

.slick-arrow.slick-hidden {
  display: none;
}

/***SLICK THEME****/

.slick-loading .slick-list {
  background: #fff url(../images/ajax-loader.gif) center center no-repeat;
}

/* Arrows */

.slick-prev,
.slick-next {
  display: block;
  position: absolute;
  top: 50%;
  z-index: 10;
  min-width: 0;
  width: 20px;
  height: 30px;
  margin-top: -10px \9
; /*lte IE 8*/
  padding: 0;
  font-size: 0;
  background: transparent;
  line-height: 0;
  color: transparent;
  border: none;
  outline: none;
  box-shadow: none;
  cursor: pointer;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
}

.slick-prev:hover,
.slick-prev:focus,
.slick-next:hover,
.slick-next:focus {
  color: transparent;
  outline: none;
  background: transparent;
}

.slick-prev:hover:before,
.slick-prev:focus:before,
.slick-next:hover:before,
.slick-next:focus:before {
  opacity: 1;
}

.slick-prev.slick-disabled:before,
.slick-next.slick-disabled:before {
  opacity: .5;
}

.slick-prev:before,
.slick-next:before {
  font-family: 'Font Awesome 5 Free';
  font-weight: 900;
  font-size: 30px;
  line-height: 1;
  opacity: 1;
  color: white;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.slick-prev {
  left: 0;
}

[dir='rtl'] .slick-prev {
  right: 0;
  left: auto;
}

.slick-prev:before {
  content: "\f053";
}

[dir='rtl'] .slick-prev:before {
  content: "\f054";
}

.slick-next {
  right: 0;
}

[dir='rtl'] .slick-next {
  right: auto;
  left: 0;
}

.slick-next:before {
  content: "\f054";
}

[dir='rtl'] .slick-next:before {
  content: "\f053";
}

/* Dots */
.slick-dots {
  position: absolute;
  bottom: 0;
  width: 100%;
  padding: 0;
  list-style: none;
  text-align: center;
  margin-left: 0;
}

.slick-dots li {
  position: relative;
  display: inline-block;
  width: auto;
  height: auto;
  margin: 0 5px;
  padding: 0;
  cursor: pointer;
}

.slick-dots li button {
  font-size: 0;
  line-height: 0;
  display: block;
  width: auto;
  height: auto;
  padding: 0;
  cursor: pointer;
  color: transparent;
  border: 0;
  outline: none;
  background: transparent;
}

.slick-dots li button:hover,
.slick-dots li button:focus {
  outline: none;
}

.slick-dots li button:before {
  content: '';
  font-size: 0;
  line-height: 20px;
  position: static;
  display: block;
  width: 15px;
  height: 15px;
  background-color: #000000;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  text-align: center;
  opacity: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-transition: all .3s ease;
  -moz-transition: all .3s ease;
  -ms-transition: all .3s ease;
  -o-transition: all .3s ease;
  transition: all .3s ease;
}

.slick-dots li button:hover:before,
.slick-dots li button:focus:before,
.slick-dots li.slick-active button:before {
  opacity: 1;
  background-color: #fff;
}
