/* ---------------------------------------
    TMPL: Demo Page
--------------------------------------- */

.page-hero {

  &--demo {
    height: 314px;
  }
}

.demo-contact {

  form {
    max-width: 730px;
    margin-left: 82px;

    @include breakpoint(medium down) {
      margin-left: auto;
      margin-right: auto;
    }
  }
}

.entry {
  position: relative;
  &__content {
    &--demo {
      margin-top: 43px;
      max-width: 480px;
    }
  }
  &__container {
    &--demo {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 90px;
    }
  }
}
.team{
  .team-member{
    & > a{
      background-position-y: top;
    }
  }
}
.news-item{
  padding-bottom: 60px;
  .date{
    display: block;
    font-size: 1.25rem;
  }
  .product__title{
    font-size: 1.5rem;
    line-height: 1.875rem;
  }
  .product__image.news__image{
    padding-bottom: 250px;
  }
}
.news-single-post{
  article{
    background-repeat: no-repeat;
    background-position: center right;
  }
  .news-content{
    margin-top: 75px;
    margin-bottom: 75px;
    margin-left: auto;
    margin-right: auto;
    max-width: 720px;
    h2{
      &::before{
        display: none;
      }
    }
    h3{
      font-size: 1.5rem;
      &::after{
        display: none;
      }
    }
    p + h3{
      padding-top: 80px;
    }
  }
}
