thead,
tbody,
tbody tr,
tfoot {
  background-color: transparent;
}

.woocommerce-cart {
  .page-title {
    display: none;
  }

  h1 {
    @include breakpoint(medium down) {
      margin-top: 30px;
    }
  }

  .main-content {
    margin-top: 90px;

    @include breakpoint(medium down) {
      margin-top: 0;
    }
  }

  table.cart {

    img {
      width: auto;
      max-width: 100px;
    }
  }

  table.cart {

    .product-thumbnail {
      width: 100px;
    }
  }
}

.woocommerce-cart-form {

  &__cart-item {
    display: flex;
    justify-content: space-between;
    margin-bottom: 29px;
    border-bottom: 1px solid $frst-fnt-clr;
    padding-bottom: 42px;
  }
}

.woocommerce {

  form .form-row .required {
    color: inherit;
  }

  a.remove {
    color: $frst-fnt-clr !important;
    border: 1px solid currentColor;
    font-weight: 400;
    margin-left: auto;

    &:focus,
    &:hover {
      background-color: $hvr-clr;
    }
  }

  .product {

    &-remove {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }

    &-thumbnail {
      max-width: 100px;

      a {
        position: relative;
        display: inline-block;
        max-width: 100px;

        &::before {
          content: '';
          display: block;
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          background-color: rgba(255,255,255,0.3);
          opacity: 0;
          transition: opacity .3s ease-in-out;
        }

        &:focus,
        &:hover {

          &::before {
            opacity: 1;
          }
        }
      }
    }

    &-name {
      width: 100%;
      margin-left: 30px;
      margin-right: 30px;
    }
  }
}

.order {

  &__container {
    padding-left: 30px;
    padding-right: 30px;

    .product-total {
      padding-top: 5px !important;
      p {
        margin-bottom: 0;
      }
    }

    tfoot {
      color: $frst-fnt-clr;

      td,
      th {
        text-align: right;
        border-top: none !important;
      }
    }
  }

}

.coupon {

  &__label {
    margin-bottom: 0;
  }

  &__input {
    background-color: transparent;
    padding-top: 20px;
    padding-bottom: 20px;
    padding-left: 30px;
    height: auto;
    border: 1px solid $frst-thm-clr;
    margin-top: 40px;
    margin-bottom: 40px;
    color: $frst-fnt-clr;

    &:focus {
      border: 1px solid $frst-thm-clr;
    }
  }

  &__button {
    padding: 0;
    background-color: transparent;
    text-decoration: underline;
    text-transform: uppercase;
    font-weight: 600;
    font-size: 14px;

    &:hover,
    &:focus {
      background-color: transparent;
      color: $main-color;
    }
  }
}

.shop_table.shop_table_responsive {
  tr {
    text-align: right;
    border-bottom: none;
  }

  th {
    width: auto;
    border-top: none;
  }
}

.woocommerce-cart {

  .wc-proceed-to-checkout{
    text-align: right;

    a.checkout-button {
      display: inline-block;
    }
  }

  .cart-collaterals {

    .cart_totals {

      tr {
        font-size: 1.125rem;

        th,
        td {
          border-top: none;
        }

        td {
          font-weight: 700;
          @include breakpoint(large) {
            width: 170px;
          }
        }

        th {
          width: auto;
          font-weight: 400;
        }

        &.order-total {
          font-size: 1.5rem; // 24px
        }

        //&.cart-subtotal {
        //  .tax_label {
        //    display: none;
        //  }
        //}
      }
    }
  }
}
