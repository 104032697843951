.cart-menu {
  position: fixed;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 10;
  width: 100%;
  max-width: 500px;
  padding: 70px 30px 110px;
  /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#00aa00+0,006600+100 */
  background: #00aa00; /* Old browsers */
  background: -moz-linear-gradient(top, #00aa00 0%, #006600 100%); /* FF3.6-15 */
  background: -webkit-linear-gradient(top, #00aa00 0%, #006600 100%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom, #00aa00 0%, #006600 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00aa00', endColorstr='#006600', GradientType=0); /* IE6-9 */
  transform: translateX(100%);
  transition: transform 0.3s ease-in-out;

  .tax-total,
  .total {
    display: none;
  }

  &.cart-menu--active {
    transform: translateX(0);
  }

  &__wrapper {
    p {
      margin-bottom: 9px;
      line-height: 1.2;
    }
  }

  &__image-wrap {
    position: relative;
    margin-right: 30px;
  }

  .remove.remove_from_cart_button {
    position: absolute;
    top: 5px;
    right: 5px;
    z-index: 10;
    background-color: $frth-thm-clr;
    font-weight: 400 !important;
    width: 1rem !important;
    height: 1rem !important;
    font-size: 1rem !important;
    line-height: 1;
    color: $ffth-fnt-clr !important;
    text-align: center;
    border-radius: 50%;
    border: 1px solid currentColor;

    &:focus,
    &:hover {
      color: $frst-fnt-clr !important;
      background-color: $hvr-clr;
    }
  }

  .woocommerce-mini-cart-item {
    position: relative;
    display: flex;
    align-items: flex-start;
  }

  .woocommerce-mini-cart-item + .woocommerce-mini-cart-item {
    margin-top: 30px;
  }

  ul.cart_list li {
    a {
      display: inline-block;
    }

    img {
      float: none;
      width: 100px;
      height: 100px;
      margin-left: 0;
    }
  }

  &__close {
    position: absolute;
    top: 20px;
    right: 20px;
    padding: 0;
    background-color: transparent !important;
    font-size: 30px;

    &:hover,
    &:focus {
      color: $main-color;
    }
  }

  &__title {
    margin-bottom: 0;

    &::before {
      display: none;
    }
  }

  & &__footer {
    display: flex;
    justify-content: space-between;
    border-top: 1px solid $frst-thm-clr;
    padding-top: 24px;
    position: absolute;
    bottom: 30px;
    left: 30px;
    right: 30px;
    z-index: 10;
  }

  .woocommerce-mini-cart {
    overflow: auto;
    max-height: calc(100vh - (180px + 43px + 40px + 30px));

    &__buttons {
      .checkout {
        @extend .button--green;
      }
    }
  }
}
