/* ---------------------------------------
    Typography
--------------------------------------- */

body {
  font-size: 16px;
  color: $frst-fnt-clr;
  line-height: 1.5;
}

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  a {
    color: inherit;
  }
}

h1, .h1,
h2, .h2,
h3, .h3 {
  font-size: 2.25rem; // 36px
  line-height: 1.22;
  margin-bottom: 3.375rem; // 54px

  &::before {
    content: '';
    display: inline-block;
    background-color: $thrd-thm-clr;
    width: 20px;
    height: 4px;
    vertical-align: middle;
    margin-right: 10px;
  }
}

h1, .h1,
h2, .h2 {

  &::before {
    content: '';
    display: inline-block;
    background-color: $thrd-thm-clr;
    width: 20px;
    height: 4px;
    vertical-align: middle;
    margin-right: 10px;
  }
}

h3, .h3 {

  &::before {
    display: none;
  }

  &::after {
    content: '';
    display: block;
    width: 20px;
    height: 4px;
    margin: 23px auto 50px;
    background-color: $thrd-thm-clr;
  }

}

h4, .h4 {
  font-size: 1.875rem; // 30px
  line-height: 1.2;
}

h5, .h5 {
  font-size: 1.25rem; //20px
  line-height: 1.2;
  margin-bottom: 2rem;
}

h6, .h6 {
  /*basic heading styles*/
}

.accent-color {
  color: $scnd-fnt-clr;
}

.text-size {

  &__huge {
    font-size: 1.75rem; // 28px
    line-height: 1.4;
  }

  &__big {
    font-size: 1.25rem; // 20px
    line-height: 1.4;
  }

  &__large {
    font-size: 1.125rem; // 18px
  }

  &__small {
    font-size: 0.875rem; // 14px
    line-height: 1.7;
  }

  &__tiny {
    font-size: 0.75rem; // 14px
    line-height: 1.7;
  }
}

.text-weight {

  &__bold {
    font-weight: 700;
  }
}

.truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

p {
  /*basic paragraph styles*/

  &:last-child {
    margin-bottom: 0;
  }

  &:empty {
    display: none;
  }
}

ul {
  /*basic list styles*/
  font-size: inherit;

  li {
    /*basic list-item styles*/
  }
}

address {
  font-style: normal;
  margin-bottom: $global-margin;
}

a {
  color: $frst-fnt-clr;
  transition: all 0.2s ease-in-out;

  &:hover, &:focus {
    /*basic hover and focus effects*/
    color: $scnd-fnt-clr;
  }
}

/* ---------------------------------------
    Forms/Inputs
--------------------------------------- */

/* Placeholder styling */

input, textarea {
  &::placeholder {
    opacity: 1;
    color: $frst-fnt-clr;
  }
}

/* Input Styling */

[type=color],
[type=date],
[type=datetime-local],
[type=datetime],
[type=email],
[type=month],
[type=number],
[type=password],
[type=search],
[type=tel],
[type=text],
[type=time],
[type=url],
[type=week],
textarea,
select,
.select-styled {
  $height: ($input-font-size * unitless-calc($input-line-height)) + (get-side($input-padding, 'top') + get-side($input-padding, 'bottom')) + rem-calc(get-border-value($input-border, 'width') * 2);
  /* STYLES GO THERE */
  height: $height;
  margin-bottom: 0;

  &:focus {
    background-color: transparent;
    border: none;
    border-bottom: 1px solid $frst-thm-clr;
  }
}

textarea[rows] {
  /*Styles for TextArea*/
  height: 150px;
  max-width: 100%;
}

[type=number] {
  -moz-appearance: textfield;
}

[type=number]::-webkit-inner-spin-button,
[type=number]::-webkit-outer-spin-button {
  margin: 0;
  -webkit-appearance: none;
}

[type=checkbox],
[type=file],
[type=radio] {
  margin-bottom: 0;
}

label {
  color: inherit;
}

button, .button, input[type='submit'], input[type='reset'], input[type='button'] {
  border-width: $button-hollow-border-width;
  transition: all 0.3s ease-in-out;

  &:hover, &:focus {
    background-color: $hvr-clr;
  }
}

.submenu-toggle {
  background-color: lighten($main-color, 5);;
}

/* Button additional styles */

.button {
  text-decoration: none;
  font-size: 0.875rem !important; // 14px
  font-weight: 600;
  text-align: center;

  &.checkout-button,
  &.single_add_to_cart_button,
  &--black {
    background-color: transparent;
    position: relative;
    z-index: 1;
    margin-right: 5px;
    margin-top: 5px;
    margin-bottom: 5px;
    min-width: 152px;
    text-transform: uppercase;

    @include breakpoint(small down) {
      min-width: 120px;
    }

    &::before {
      content: '';
      display: block;
      position: absolute;
      background-color: $scnd-thm-clr;
      top: 5px;
      left: 5px;
      bottom: -5px;
      right: -5px;
      z-index: -1;
      transition: top .2s ease-in-out, left .2s ease-in-out;
    }

    &::after {
      content: '';
      display: block;
      position: absolute;
      background-color: $frth-thm-clr;
      border: 1px solid $frst-thm-clr;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: -1;
      transition: background-color .2s ease-in-out .2s;
    }

    &:hover,
    &:focus {
      color: $frst-fnt-clr !important;

      &::after {
        background-color: $scnd-thm-clr;
      }

      &::before {
        top: -5px;
        left: -5px;
      }
    }
  }

  &--green {
    background-color: transparent;
    position: relative;
    z-index: 1;
    margin-right: 5px;
    margin-top: 5px;
    margin-bottom: 5px;
    min-width: 152px;
    text-transform: uppercase;
    color: $frst-fnt-clr;

    @include breakpoint(small down) {
      min-width: 120px;
    }

    &::before {
      content: '';
      display: block;
      position: absolute;
      background-color: $sxth-thm-clr;
      border: 1px solid $frst-thm-clr;
      top: 5px;
      left: 5px;
      bottom: -5px;
      right: -5px;
      z-index: -1;
      transition: top .2s ease-in-out, left .2s ease-in-out;
    }

    &::after {
      content: '';
      display: block;
      position: absolute;
      background-color: $ffth-thm-clr;
      border: 1px solid $frst-thm-clr;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: -1;
      transition: background-color .2s ease-in-out .2s;
    }

    &:hover,
    &:focus {
      color: $frst-fnt-clr !important;

      &::after {
        background-color: $sxth-thm-clr;
      }

      &::before {
        top: -5px;
        left: -5px;
      }
    }
  }

  &--widget {
    font-size: inherit;
    font-weight: inherit;
    padding: 0;
    background-color: transparent;
    display: block;
    width: 100%;
    text-align: left;
    border-width: 0;
    outline: none;

    &::after {
      content: '';
      display: inline-block;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 3.5px 0 3.5px 4px;
      border-color: transparent transparent transparent currentColor;
      position: absolute;
      right: 0;
      top: 0;
      bottom: 0;
      margin-top: auto;
      margin-bottom: auto;
      transform: rotate(0);
      transition: transform .3s ease-in-out;
    }

    &.active {
      color: $hvr-clr;

      &::after {
        transform: rotate(90deg);
      }
    }


    &:focus,
    &:hover {
      color: $hvr-clr;
      background-color: transparent;
    }
  }
}

.widget {

  &__title {
    position: relative;
    margin-bottom: 0;
  }
}

.woocommerce {

  button.button {
    color: $frst-fnt-clr;
  }
}

@include breakpoint(medium down) {
  html,
  body {
    font-size: 14px;
  }
}

@include breakpoint(small down) {
  html,
  body {
    font-size: 13px;
  }
}


