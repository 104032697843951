.instagram {

  &__image {
    padding-bottom: 100%;
    display: block;
  }

  &__image-link {
    display: block;
  }

  &__cell {
    border: 1px solid $frst-thm-clr;
    padding: 9px;
  }

  &__text {
    border: 1px solid $frst-thm-clr;
    display: flex;
    height: 100%;
    flex-direction: column;
    justify-content: center;
    padding: 5px;
  }
}
