/* ---------------------------------------

--------------------------------------- */
.demo-contact,
.contact {

  .gform_fields {
    display: flex;
    flex-wrap: wrap;
    margin-left: -.9375rem;
    margin-right: -.9375rem;
  }

  .gfield {
    flex: 0 0 auto;
    margin-left: .9375rem;
    margin-right: .9375rem;
    width: calc(100% - 1.875rem);
  }

  .gfield-6 {
    width: calc(50% - 1.875rem);
  }

  @include breakpoint(small down) {

    .gfield-6 {
      width: calc(100% - 1.875rem);
    }
  }

  @include breakpoint(medium) {

    .gform {

      &_button {
        margin-top: -230px;
      }
    }
  }

}
