.logo {
  img {
    max-height: 150px;
  }
}

.header {
  font-size: 14px;
  font-weight: 600;

  @include breakpoint(medium down) {
    margin-bottom: 20px;
  }
}

.header-sub-menu {

  .menu-item + .menu-item {
    margin-left: 40px;
  }

  .menu-item {

    &.login,
    &.cart {

      a {

        &::before {
          content: "\f291";
          font-weight: 900;
          font-family: 'Font Awesome 5 Free';
          margin-right: 12px;
          font-size: 114%;

        }
      }
    }

    &.cart {

      a {

        &::before {
          content: "\f291";
        }
      }
    }

    &.login {

      a {

        &::before {
          content: "\f007";
        }
      }
    }

  }
}

.header-top-bar {
  position: relative;
  padding-top: 10px;
  padding-bottom: 8px;
  margin-bottom: 9px;

  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 15px;
    right: 15px;
    border-bottom: 1px solid $frst-thm-clr;
  }
}

.contacts {

  &--header {
    margin-left: 3px;

    i {
      font-size: 114%;
      margin-right: 10px;
    }
  }

  &__item + &__item {
    margin-left: 40px;
  }

  &__link {
    color: $frst-fnt-clr;

    &:hover,
    &:focus {
      color: $hvr-clr;
    }
  }
}

.header-menu {

  a {
    text-transform: uppercase;
  }
}

@include breakpoint(medium down) {

  .header-menu {
    margin-right: auto;
  }
}

@include breakpoint(small down) {
  /* TOP BAR Mobile STYLING */

  .contacts--header {

    .contacts {

      &__link {
        font-size: 0 !important;

        i {
          font-size: 1.2rem;
        }
      }
    }
  }

  .header-sub-menu {

    a {
      font-size: 0;

      &::before {
        font-size: 1.2rem !important;
      }
    }

    .menu-item + .menu-item {
      margin-left: 20px;
    }
  }

  .menu {

    &__cell {
      width: 100% !important;
      margin-left: 0 !important;
      margin-right: 0 !important;
    }
  }

  .menu-grid-container {
    padding-left: 0;
    padding-right: 0;

    .grid-margin-x {
      margin-left: 0;
      margin-right: 0;

      .cell {
        margin-left: 0;
        margin-right: 0;
        width: 100%;
      }
    }
  }

  .title-bar {
    line-height: 45px;
    background-color: $frth-thm-clr;

    .menu-icon {
      padding: 0;
      border: none;
      background-color: transparent;

      @include menu-icon(#fff, #fff, 30px, 24px, 3px);
    }

  }

  .top-bar {
    display: none;
    background: $main-color; /* menu list background color */
  }

  .header-menu {
    background: transparent;

    li {
      > a {
        background: $main-color; /* menu item background color */
        color: #fff; /* menu item text color */
        line-height: 45px;

        &:hover {
          background: darken($main-color, 5); /* menu item background color on hover */
        }
      }

      &.current-menu-item, &.current_page_ancestor, &.current_page_parent {
        > a, .submenu-toggle {
          background: lighten($main-color, 5); /* current/parent menu item background color */
        }
      }

      &[aria-expanded='true'] {
        > a, .submenu-toggle {
          background: darken($main-color, 3); /* menu item background color on open dropdown */
        }
      }
    }

    .submenu-toggle {
      padding: 0;

      &:hover,
      &:focus {
        background-color: $scnd-thm-clr;
      }
    }

    .submenu {
      a {
        padding-left: #{get-side($accordionmenu-padding, 'left') * 1.65};
      }

      .submenu {
        a {
          padding-left: #{get-side($accordionmenu-padding, 'left') * 2.3};
        }
      }
    }
  }
}

@include breakpoint(medium) {
  /* TOP BAR STYLING */
  .button--gratis {
    text-align: center;
    background-color: transparent;
    position: relative;
    z-index: 1;
    margin-right: 5px;
    margin-top: 5px;
    margin-bottom: 5px;
    min-width: 152px;

    a {
      color: $frst-fnt-clr !important;
    }

    &::before {
      content: '';
      display: block;
      position: absolute;
      background-color: $scnd-thm-clr;
      top: 5px;
      left: 5px;
      bottom: -5px;
      right: -5px;
      z-index: -1;
      transition: top .2s ease-in-out, left .2s ease-in-out;
    }

    &::after {
      content: '';
      display: block;
      position: absolute;
      background-color: $frth-thm-clr;
      border: 1px solid $frst-thm-clr;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: -1;
      transition: background-color .2s ease-in-out .2s;
    }

    &:hover,
    &:focus {
      color: $frst-fnt-clr !important;

      &::after {
        background-color: $scnd-thm-clr;
      }

      &::before {
        top: -5px;
        left: -5px;
      }
    }
  }

  .header-menu.menu {
    /* Align menu items to the right */
    justify-content: space-between;
    align-items: center;
    max-width: 100%;
    margin-left: auto;

    /* Align menu items to the center */
    // justify-content: center;

    li {
      > a {
        /* Regular menu item style */
        line-height: 40px;
        color: $frst-fnt-clr;

        &:hover {
          /* menu item style on hover */
          color: $hvr-clr;
        }
      }

      &.current-menu-item, &.current_page_ancestor, &.current_page_parent {
        > a {
          /* Style of Current page and Ancestor page */
          color: $hvr-clr;
        }
      }

      &.has-dropdown {
        > a {
          /* Style of item with dropdown */
          position: relative;

          &::after {
            content: '';
            display: inline-block;
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 4px 3.5px 0 3.5px;
            border-color: currentColor transparent transparent transparent;
            position: absolute;
            right: 0;
            top: 0;
            bottom: 0;
            margin-top: auto;
            margin-bottom: auto;
          }
        }
      }

      &.is-active > a {
        /* Parent link style on dropdown hover */
        color: $hvr-clr;
      }
    }

    /* Dropdown item styling */

    .submenu {
      z-index: 11;
      display: none;
      border: 1px solid $frst-thm-clr;
      background-color: $frth-thm-clr;
      visibility: hidden;
      display: block;
      transition: visibility 0s linear .25s, opacity .25s ease-in;
      opacity: 0;

      &.js-dropdown-active {
        display: block;
        opacity: 1;
        visibility: visible;
        transition-delay: 0s;
      }

      li {
        > a {
          /* Regular link in dropdown */
          color: $frst-fnt-clr;
          line-height: 30px;

          &:hover {
            /* Dropdown link on hover */
            color: $hvr-clr;
          }
        }

        &.current-menu-item, &.current_page_ancestor, &.current_page_parent {
          > a {
            color: $hvr-clr;
          }
        }
      }

      .submenu {
        /* Styles for 2nd level dropdown menu */
      }
    }
  }
}
body:not(.woocommerce-checkout){  
  article.page-article{
    height: 400px;
    padding: 100px 0px;
  }
}
