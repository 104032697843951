.woocommerce-form-register {
  border: none !important;
}

.woocommerce-form-login {
  border: none !important;
  max-width: 390px;
  margin-left: auto !important;
  margin-right: auto !important;

  &__title {
    margin-top: 3.75rem;
  }

  .form-row:last-of-type {
    text-align: center;
  }

  &__rememberme {
    display: block !important;
  }

  &__submit {
    @extend .button--green;
    float: none !important;
  }
}

.woocommerce-account {

  .woocommerce-form__label-for-checkbox {
    position: relative;
    cursor: pointer;
    text-align: left;

    .woocommerce-form__input-checkbox {
      width: 0;
      height: 0;
      position: absolute;
      visibility: hidden;

      + span {
        position: relative;
        margin: 0;
        padding-left: 30px;
        font-size: 14px;
        line-height: 1.7;
        color: $frst-fnt-clr;

        &::before {
          content: '';
          border: 1px solid $frst-fnt-clr;
          width: 12px;
          height: 12px;
          display: block;
          position: absolute;
          left: 0;
          top: 2px;
          bottom: 0;
        }

        &::after {
          content: '\f00c';
          font-family: 'Font Awesome 5 Free';
          font-weight: 900;
          position: absolute;
          left: 0;
          top: -3px;
          bottom: 0;
          display: none;
        }
      }

      &:checked {

        + span {

          &::after {
            display: block;
          }
        }
      }
    }
  }

  .woocommerce-Button {
    @extend .button--black;
  }

  input {
    border-top: none;
    border-right: none;
    border-left: none;
    background-color: transparent;
  }

  .col2-set {
    h2 {
      display: none;
    }

    .col-2 {
      float: left;
    }

    .col-1 {
      float: right;
      /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#00cc00+0,006600+100 */
      background: #00cc00; /* Old browsers */
      background: -moz-linear-gradient(top, #00cc00 0%, #006600 100%); /* FF3.6-15 */
      background: -webkit-linear-gradient(top, #00cc00 0%,#006600 100%); /* Chrome10-25,Safari5.1-6 */
      background: linear-gradient(to bottom, #00cc00 0%,#006600 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00cc00', endColorstr='#006600',GradientType=0 ); /* IE6-9 */
    }
  }

  .lost_password {
    display: none !important;
  }
}
.woocommerce:not(.logged-in) .summary.entry-summary form.cart, .woocommerce:not(.logged-in) .menu-header-sub-menu-container li.cart{
  display: none;
}
