.img-text {
  &__image {
    border: 1px solid $frst-thm-clr;
  }
}

.im-text-slider {
  &.slick-initialized .slick-slide {
    display: flex;
  }
}
