.brand {

  &__row {
    margin-top: 40px;
    margin-bottom: 40px;
    & > div{
      position: relative;
    }
    h4{

    }
  }

  &__image {
    display: block;
    top: 50%;
    transform: translateY(-50%);
    border: 1px solid #fff;
    padding: 15px;
    & > div{
      width: 100%;
      height: 100px;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
    }
  }
}
