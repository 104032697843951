// Common
.woocommerce {

  &.archive {

    .product {

      &__title {
        font-size: 20px;
      }

      &__price,
      &__cat {
        font-size: 16px;
      }
    }
  }

  .tabs {
    display: none;
  }

  .quantity {
    margin-right: 30px !important;
    max-width: 150px;

    @include breakpoint(small down) {
      margin-right: 15px !important;
      max-width: 120px;
    }

    .qty {
      width: 100%;
      border: none;
      border-bottom: 1px solid $frst-thm-clr;
      background-color: transparent;
      color: $frst-thm-clr;
      text-align: left;
    }
  }

  div.product {
    margin: 0 -15px 80px;

    @include breakpoint(large down) {
      max-width: 600px;
      margin-left: auto;
      margin-right: auto;
    }

    &::before,
    &::after {
      display: table;
      content: ' ';
      flex-basis: 0;
      order: 1;
    }

    &::after {
      clear: both;
    }

    div.summary {
      margin-top: 70px;
      float: left;
    }

    div.images {
      float: right;
    }

    div {

      &.images,
      &.summary {
        width: 50%;
        padding: 0 15px;

        @include breakpoint(large down) {
          width: 100%;
        }
      }
    }


    .quantity {
      position: relative;

      &__label {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        padding: 0 15px;
        transition: top .25s ease-out, transform .25s ease-out, font-size .25s ease-out, color .25s ease-out, padding-left .25s ease-out;
        padding-top: 8px;

        &:focus {
          top: 0;
        }
      }

      &__focus {

        .quantity__label {
          transform: translateY(-100%);
          font-size: 12px;
          padding-left: 0;
        }
      }
    }

    .product_title {
      width: 75%;
      display: inline-block;
    }

    p.price {
      width: 25%;
      display: inline-block;
      margin-left: -4px;
      text-align: right;
      font-size: 1.875rem;
      font-weight: 600;
      color: $ffth-fnt-clr;
      vertical-align: top;
    }

    .product_title,
    p.price {
      @include breakpoint(small down) {
        width: 100%;
      }
    }

  }

  ul.products {
    margin-right: -.9375rem;
    margin-left: -.9375rem;
    justify-content: center;
    display: flex;
    flex-flow: row wrap;
    justify-content: center;

    &.columns-3 li.product {
      padding-right: .9375rem;
      padding-left: .9375rem;
      width: 33.33333%;
      margin-right: unset;
      flex: 0 0 auto;
      text-align: center;

      @include breakpoint(large down) {
        width: 50%;
      }

      @include breakpoint(small down) {
        width: 100%;
      }
    }
  }

  &.single {

    div.product {
      form.cart .button {
        margin-top: 12px;
      }

      .product_title {
        margin-bottom: 0;
      }

      p.price {
        margin-bottom: 0;
      }

      .breadcrumbs {
        margin-bottom: 50px;
      }
    }
  }

  &.archive {

    .term-description {
      margin-bottom: 30px;
    }
  }
}

.related {
  text-align: center;
}

.summary {

  &__download {
    margin-top: 30px;
    text-decoration: underline;
    display: inline-block;
  }
}

.woocommerce-product-details {

  &__short-description {
    max-width: 480px;
  }
}

.products-list {

  &__sidebar {

    aside {
      padding: 20px 30px;
      border: 1px solid $frst-thm-clr;
    }
  }
}

.brands,
.product-categories {
  @include list-unstyled;
  margin-top: 15px;
  font-size: 11px;
  font-weight: 700;
  display: none;
}

.cat-item {

  a {
    padding: 8px 0;
    display: inline-block;
    line-height: 1;
  }
}

.tagcloud {
  margin: 20px -7px 0;
  display: none;
}

.tag-cloud {

  &-link {
    display: inline-block;
    padding: 5px 7px;
    border: 1px solid currentColor;
  }
}

.prod-cats {

  .cat-item {
    display: inline-block;
    white-space: nowrap;

    &::after {
      content: '';
      display: inline-block;
      background-image: url(../images/arrow-top-right.svg);
      width: 23px;
      height: 23px;
      vertical-align: middle;
      margin-right: 10px;
    }
  }
}
// end of Common

//Cart
@import 'cart';
//end of Cart

//Cart menu
@import 'cart-menu';
//end of Cart menu

// Checkout
@import 'checkout';
// end of Checkout

// Account
@import 'account';
// end of Account
